import React, { Component } from "react"
import "@components/form_dw_personal_info/style.scss"
import axios from "axios"
import * as headers from "@data/headers"
import FormButton from "@components/_atoms/button_form_input_submit"
import RadioWithCheck from "@components/_atoms/button_radio_checkmark"
import HiddenErrorHandler from "@components/_atoms/HiddenErrorHandler"
import * as errors from "@services/errors"
import HeaderAndText from "@components/header_with_text"
import BackButton from "@components/back_button"
import LAEJS from "@services/LAEJS"

class IndividualEmploymentForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      empStatus: "",
      citizenship: "",
      citizenError: false,
      generalError: null,
      buttonSubmitted: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    LAEJS.track("screen_view.student_employment_status")
  }

  handleChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({ [name]: value })
  }

  handleSubmit = (ev) => {
    ev.preventDefault()
    let _this = this
    _this.setState({ buttonSubmitted: true })
  }

  errorHandler = (err) => {
    this.setState({
      buttonSubmitted: false,
      generalError: errors.messaging(err),
    })
  }

  isDisabled = () => {
    if (this.state.empStatus === "" || this.state.buttonSubmitted === true) {
      return true
    } else {
      return false
    }
  }

  render() {
    return (
      <React.Fragment>
        <BackButton navigate={this.props.navigate} />
        <HeaderAndText data={this.props.data.heading} />
        {console.log(this.state.empStatus)}
        <div className="employment-status">
          <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
            <RadioWithCheck
              data={{
                id: "employed",
                name: "empStatus",
                value: "EMPLOYED",
                checked: this.state.empStatus === "EMPLOYED",
              }}
            >
              I am fully employed
            </RadioWithCheck>
            <RadioWithCheck
              data={{
                id: "selfEmployed",
                name: "empStatus",
                value: "SELF_EMPLOYED",
                checked: this.state.empStatus === "SELF_EMPLOYED",
              }}
            >
              I am self-employed
            </RadioWithCheck>
            <RadioWithCheck
              data={{
                id: "notEmployed",
                name: "empStatus",
                value: "UNEMPLOYED",
                checked: this.state.empStatus === "UNEMPLOYED",
              }}
            >
              I am not employed
            </RadioWithCheck>
            <RadioWithCheck
              data={{
                id: "student",
                name: "empStatus",
                value: "STUDENT",
                checked: this.state.empStatus === "STUDENT",
              }}
            >
              I am a student
            </RadioWithCheck>
            <RadioWithCheck
              data={{
                id: "retired",
                name: "empStatus",
                value: "RETIRED",
                checked: this.state.empStatus === "RETIRED",
              }}
            >
              I am retired
            </RadioWithCheck>

            <div style={{ width: `100%`, height: `3rem` }}></div>
            <HiddenErrorHandler error={this.state.generalError} />
            <FormButton
              isDisabled={this.isDisabled()}
              onClick={() =>
                LAEJS.track("button_tap.student_employment_status_next")
              }
            />
          </form>
        </div>
      </React.Fragment>
    )
  }
}

export default IndividualEmploymentForm
