const commonSubHeader =
  "As an SEC registered investment adviser, we are required to collect this information to protect you."

const commonRegButton = {
  link: "",
  text: "Continue"
}
const commonPersonal = {
  heading: {
    header: "Personal Info",
    text: commonSubHeader
  },
  regButton: commonRegButton
}

// EXPORTS =============================

export const personalInfoForm = Object.assign({}, commonPersonal, {
  backLinkNative: true,
  tracking: {
    screenView: "screen_view.student_personal_info",
    buttonTapContinue: "button_tap.student_personal_info_next"
  }
})

export const addressForm = Object.assign({}, commonPersonal)

export const individualEmploymentForm = {
  heading: {
    header: "Select which statement is true"
  },
  regButton: commonRegButton
}

export const statementForm = {
  heading: {
    header: "Select which statements apply"
  },
  regButton: commonRegButton
}

export const individualCitizenshipForm = {
  heading: {
    header: "Please confirm"
  },
  regButton: commonRegButton
}

export const applicationReview = {
  heading: {
    header: "Please review your account application",
    text:
      "As a registered investment advisor we are required by the U.S. government to collect this information."
  }
}
