import React, { useState, useEffect, useRef } from "react"
import { Router, Location } from "@reach/router"
import { navigate } from "gatsby"
import SEO from "@components/seo"
import * as data from "@data/individual"
import Layout from "@components/layout"
import PersonalInfoForm from "@components/_molecules/form_personal_info"
import AddressForm from "@components/_molecules/form_address"
import IndividualEmploymentForm from "@components/_molecules/form_individual_employment"
import StatementForm from "@components/_molecules/form_statements"
import IndividualCitizenshipForm from "@components/_molecules/form_individual_citizenship"
import ApplicationReview from "@components/_molecules/individual_application_review"
import { localStorageInitQueryKeys } from "@services/LocalStorage"

const IndividualRegistration = () => {
  localStorageInitQueryKeys()

  const [currentStep, setCurrentStep] = useState("")
  const setNextStep = (nextStep: string) => {
    setCurrentStep(nextStep)
  }

  return (
    <Layout type="gray" pageClass="pledge">
      <SEO title="Registration" />
      <Location>
        {({ location }) => {
          return (
            <Router basepath="/individual-registration">
              <PersonalInfoForm
                path="/"
                data={data.personalInfoForm}
                currentStep={currentStep}
                setNextStep={setNextStep}
                location={location}
                navigate={navigate}
                nextForm={"/individual-registration/address"}
                trackScreenViewEvent="screen_view.student_info_"
                trackButtonTapEvent="button_tap.student_info_next"
                trackLearnMoreEvent="button_tap.student_info_learnmore"
                trackItinEvent="button_tap.student_ITIN"
              />
              <AddressForm
                path="/address"
                data={data.addressForm}
                currentStep={currentStep}
                setNextStep={setNextStep}
                location={location}
                navigate={navigate}
                nextForm="/individual-registration/employment"
                trackScreenViewEvent="screen_view.student_personal_info_address"
                trackButtonTapEvent="button_tap.student_personal_info_address_next"
              />
              <IndividualEmploymentForm
                path="/employment"
                data={data.individualEmploymentForm}
                currentStep={currentStep}
                setNextStep={setNextStep}
                location={location}
                navigate={navigate}
              />
              <StatementForm
                path="/verify-statements"
                data={data.statementForm}
                currentStep={currentStep}
                setNextStep={setNextStep}
                location={location}
                navigate={navigate}
                nextForm="/individual-registration/citizenship"
              />
              <IndividualCitizenshipForm
                path="/citizenship"
                data={data.individualCitizenshipForm}
                currentStep={currentStep}
                setNextStep={setNextStep}
                location={location}
                navigate={navigate}
              />
              <ApplicationReview
                path="/verify-info"
                data={data.applicationReview}
                currentStep={currentStep}
                setNextStep={setNextStep}
                location={location}
                navigate={navigate}
              />
            </Router>
          )
        }}
      </Location>
    </Layout>
  )
}

export default IndividualRegistration
