import React, { Component } from "react"
import axios from "axios"
import * as headers from "@data/headers"
import "./style.scss"
import FormButton from "@components/_atoms/button_form_input_submit"
import SelectCitizenship from "@components/_atoms/select_citizenship"
import { countries } from "@data/constants/countries"
import * as errors from "@services/errors"
import HeaderAndText from "@components/header_with_text"
import BackButton from "@components/back_button"
import HiddenErrorHandler from "@components/_atoms/HiddenErrorHandler"
import { EyebrowRegular } from "@components/_atoms/Text"
import LAEJS from "@services/LAEJS"

class IndividualCitizenshipForm extends Component {
  constructor(props) {
    super(props)
    this.state = { citizenship: "USA", generalError: null }
  }

  componentDidMount() {
    let { citizenshipCountry } = localStorage

    if (citizenshipCountry) {
      this.setState({ citizenship: citizenshipCountry })
    }
    LAEJS.track("screen_view.student_citizenship")
  }

  handleChange = event => {
    const target = event.target,
      value = target.value,
      name = target.name

    this.setState({
      [name]: value
    })
  }
  
  handleSubmit = ev => {
    ev.preventDefault()
    let _this = this
    _this.setState({ buttonSubmitted: true })
  }

  countryOptions = (option, index) => (
    <option key={index} value={option.value}>
      {option.name}
    </option>
  )

  errorHandler = err => {
    this.setState({
      buttonSubmitted: false,
      generalError: errors.messaging(err)
    })
  }

  render() {
    return (
      <React.Fragment>
        <BackButton navigate={this.props.navigate} />
        <HeaderAndText data={this.props.data.heading} />

        <div className="citizenship-form">
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <p className="citizenship">Citizenship</p>
              <SelectCitizenship
                value={this.state.citizenship}
                name="citizenship"
                onChange={this.handleChange}
              >
                {countries.map((arr, index) => this.countryOptions(arr, index))}
              </SelectCitizenship>
            </div>
            <div style={{ height: "10rem" }}></div>
            <HiddenErrorHandler
              error={this.state.generalError}
              text="I agree to participate in the Junior Achievement Learn & Earn program which may include a $1/month basic course fee"
            />
            <FormButton
              onClick={() => LAEJS.track("button_tap.student_citizenship_next")}
            />
          </form>
        </div>
      </React.Fragment>
    )
  }
}

export default IndividualCitizenshipForm
