import React, { Component } from "react"
import "@components/_molecules/application_review/style.scss"
import axios from "axios"
import * as headers from "@data/headers"
import { Link } from "gatsby"
import GeneralSubmitMessage from "@components/_atoms/general_submit_message"
import FormButton from "@components/_atoms/button_form_input_submit"
import RegSuccess from "@components/_molecules/splash_link_success"
import * as errors from "@services/errors"
import LAEJS from "@services/LAEJS"
import { EVENT_TOKEN_DW_APP_SUBMITTED } from "@data/tracking_adjust"
import HeaderAndText from "@components/header_with_text"
import Toggle from "@components/_atoms/toggle_app_review"
import BackButton from "@components/back_button"
import HiddenErrorHandler from "@components/_atoms/HiddenErrorHandler"

const debug = require("debug")("lae:" + __filename)

class ApplicationReview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonSubmitted: false,
      name: "",
      email: "",
      employment: "",
      individualAddress: {
        street: "",
        apt: "",
        city: "",
        state: "",
        zip: "",
        generalError: null
      },
      childName: "",
      childAddress: {
        street: "",
        apt: "",
        city: "",
        state: "",
        zip: ""
      },
      childEmail: "",
      checkValue: false,
      regSuccess: false
    }
  }

  componentDidMount = () => {
    let { name, employmentStatus, Address, email } = localStorage

    let ia = JSON.parse(Address)

    this.setState({
      name: name,
      employment: employmentStatus,
      individualAddress: {
        street: ia.address1,
        apt: ia.address2,
        city: ia.city,
        state: ia.state,
        zip: ia.postalCode
      },
      email: email
    })
    LAEJS.track("screen_view.student_review_application")
  }
  handleCheck = () => {
    if (this.state.checkValue) {
      this.setState({ checkValue: false })
    } else {
      this.setState({ checkValue: true })
    }
  }
  handleSubmit = ev => {
    ev.preventDefault()
    this.setState({ buttonSubmitted: true })
  }

  checkStatus = () => {
    setTimeout(() => {
      let _this = this,
        accountStatusQuery = {
          query: `query {
        investmentAccounts {
          id
          status
        }
      }`,
          variables: {}
        }
      const options = {
        method: "POST",
        data: JSON.stringify(accountStatusQuery),
        headers: headers.getHeaders(),
        url: headers.api
      }
      axios(options)
        .then(res => {
          if (res.data.errors) {
            _this.errorHandler(res.data.errors[0].message)
          } else {
            let { status } = res.data.data.investmentAccounts[0]
            debug(status)
            if (status === "provisioning") {
              _this.checkStatus()
            } else if (status === "open") {
              LAEJS.jsTrackAdjustEvent({
                json: {
                  eventToken: EVENT_TOKEN_DW_APP_SUBMITTED
                }
              })
              _this.completeRegistration()
            } else {
              localStorage.setItem("dwStatus", "retry")
              localStorage.setItem(
                "advisorSysAccountId",
                res.data.data.investmentAccounts[0].id
              )
              _this.setState({
                generalError: "There was a problem processing your information",
                buttonSubmitted: false
              })
            }
          }
        })
        .catch(error => {
          localStorage.setItem("dwStatus", "retry")
          _this.errorHandler(error)
        })
    }, 2000)
  }
  completeRegistration = () => {
    this.setState({ regSuccess: true })
    window.scrollTo(0, 0)
    let $wrapper = document.querySelector("#gatsby-focus-wrapper")
    $wrapper.style.backgroundColor = "#ffa217"
    $wrapper.style.overflow = "hidden"
    $wrapper.style.height = "100vh"
    localStorage.clear()
    LAEJS.jsShowLoading()
    LAEJS.jsOnRegistrationComplete()
  }
  errorHandler = err => {
    this.setState({
      buttonSubmitted: false,
      generalError: errors.messaging(err)
    })
  }

  render() {
    const { data } = this.props

    return (
      <React.Fragment>
        <BackButton navigate={this.props.navigate} />
        <HeaderAndText data={this.props.data.heading} />

        {!this.state.regSuccess ? (
          <div className="application-review-section">
            <div className="app-review">
              <div className="links">
                <p>Name</p>
                <Link to="/individual-registration">{this.state.name}</Link>
              </div>
              <div className="links">
                <p>Employment Status</p>
                <Link
                  to="/individual-registration/employment"
                  style={{ textTransform: `capitalize` }}
                >
                  {this.state.employment === "SELF_EMPLOYED"
                    ? "Self-Employed"
                    : this.state.employment.toLowerCase()}
                </Link>
              </div>
              <div className="links address">
                <p>Address</p>
                <Link className="address" to="/individual-registration/address">
                  {this.state.individualAddress.street}{" "}
                  {this.state.individualAddress.apt}
                  <br />
                  {this.state.individualAddress.city},{" "}
                  {this.state.individualAddress.state}{" "}
                  {this.state.individualAddress.zip}
                </Link>
              </div>
              <div className="links">
                <p>Email</p>
                <Link
                  to="/individual-registration"
                  style={{ textTransform: `capitalize` }}
                >
                  {this.state.email}
                </Link>
              </div>
            </div>
            <div className="tos">
              <p>
                By clicking the “Confirm” button below, I acknowledge it is
                equivalent to my written signature and I understand that by
                doing so I am entering into legal agreements.
              </p>
              <p>
                I have carefully read, understand, and agree to the terms and
                provisions of Learn &#38; Earn’s{" "}
                <a
                  href="https://learnandearnsupport.atlassian.net/servicedesk/customer/portal/2/topic/94325f84-c9ba-4552-a7a6-96c9e04998f9/article/33423361"
                  target="_blank"
                >
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a
                  href="https://learnandearnsupport.atlassian.net/servicedesk/customer/portal/2/topic/7a83c556-8146-463d-8bfc-3ee24684b691/article/33292337"
                  target="_blank"
                >
                  Terms of Service
                </a>
                .
              </p>
              <p>
                I have carefully read, understand, and agree to the terms and
                provisions of Ant Money Advisors’{" "}
                <a
                  href="https://www.antmoneyadvisors.com/advisory-agreement/"
                  target="_blank"
                >
                  Advisory Agreement
                </a>
                , and{" "}
                <a
                  href="https://www.antmoneyadvisors.com/privacy-policy/"
                  target="_blank"
                >
                  Privacy Policy
                </a>{" "}
                and acknowledge the receipt of Ant Money Advisor’s Form ADV Part
                2A{" "}
                <a
                  href="https://www.antmoneyadvisors.com/wrap-fee-brochure-adv-part-2a/"
                  target="_blank"
                >
                  Wrap Fee Brochure
                </a>
                , Part 2B{" "}
                <a
                  href="https://www.antmoneyadvisors.com/brochure-supplement-adv-part-2b/"
                  target="_blank"
                >
                  Supplemental Brochure
                </a>
                , and{" "}
                <a
                  href="https://www.antmoneyadvisors.com/form-crs-adv-part-3/"
                  target="_blank"
                >
                  Form CRS
                </a>
                .
              </p>
              <p>
                I understand that all investing involves risk, including the
                possible loss of my investment and that the Core and Explore
                portfolios are designed for investors seeking market returns
                over the long term. I understand that my investment performance
                is not guaranteed by Ant Money Advisors, Learn &#38; Earn, nor
                the State of Delaware or California or any of its officials or
                employees, nor any federal agency including the FDIC.
              </p>
              <embed
                src="https://apps.drivewealth.com/disclosures"
                style={{ height: `130vh` }}
              ></embed>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="confirm">
                <p>I have read and agree to the terms provided.</p>
                <Toggle
                  data={{ value: this.state.checkValue }}
                  handleCheck={this.handleCheck}
                />
              </div>
              <HiddenErrorHandler error={this.state.generalError} />
              <FormButton
                text="Confirm"
                isDisabled={
                  this.state.buttonSubmitted || !this.state.checkValue
                }
                onClick={() =>
                  LAEJS.track("button_tap.student_review_application_confirm")
                }
              />
            </form>
          </div>
        ) : (
          <RegSuccess msg="Application Submitted!" />
        )}
      </React.Fragment>
    )
  }
}

export default ApplicationReview
